import { useEffect, useState } from 'react';
import {
    CollectionCategoryRecipeType,
    type RecipeImage,
    RecipeOriginType,
} from '../../../graphql/_generated-graphql-types';
import type { RecipeCollectionCategoryFragment } from '../../../graphql/_generated-operation-types';
import { useRecipeCollectionCategories } from '../../../graphql/recipe-collections/recipe-collection-categories-hook';
import type { List } from '../../../interfaces/list';
import { handleFavoriteAnalytics } from './utils/analytics';

interface UseRecipeCollectionModalProps {
    member: {
        memberships: string[];
        isRegistered: boolean;
    };
}

interface HandleOnOpenRecipe {
    id: number;
    type?: RecipeOriginType;
    images?: RecipeImage[];
}

interface HandleOnOpenOptions {
    recipeCreatedMessage?: string;
}

export interface UseRecipeCollectionModalHook {
    isModalOpen: boolean;
    handleOnOpen: (
        recipe: HandleOnOpenRecipe,
        options?: HandleOnOpenOptions,
    ) => void;
    handleOnClose: () => void;

    recipeId?: number;
    recipeCreatedMessage?: string;

    categories: RecipeCollectionCategoryFragment[];

    previousCategoryIds: number[];
    lists: List[];
    onSelectList: (categoryId: number) => void;
    createCategory: (
        name: string,
        onCategoryCreatedCompleted?: () => void,
    ) => void;
    saveList: (onSaveCompleted: () => void) => void;
    canCreateCategories: boolean;
    canFavoriteRecipes: boolean;
    hasSaveListError: boolean;
    hasCreateCategoryError: boolean;
    isLoading: boolean;
    isChangeLoading: boolean;
    isChangeError: boolean;
}

const PERSONAL_BONUS_CARD_MEMBERSHIP = 'LOYALTY';

const categoryIdMapper = (
    categories: RecipeCollectionCategoryFragment[],
    recipeId = 0,
) =>
    categories
        .filter(category =>
            Boolean(category.list.find(({ id }) => id === recipeId)),
        )
        .map(category => category.id);

export const useRecipeCollectionModalHook = ({
    member,
}: UseRecipeCollectionModalProps): UseRecipeCollectionModalHook => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [recipe, setRecipe] = useState<HandleOnOpenRecipe>();
    const [options, setOptions] = useState<HandleOnOpenOptions>();

    const {
        categories,
        assignRecipe,
        removeRecipe,
        createCategoryError,
        createCategory,
        isLoading,
        isChangeLoading,
        isChangeError,
    } = useRecipeCollectionCategories({ member });

    const categoryIds = categoryIdMapper(categories, recipe?.id);

    const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>(
        [],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            categories.length !== 0 &&
            selectedCategoryIds.sort() !== categoryIds.sort()
        ) {
            setSelectedCategoryIds(categoryIds);
        }
    }, [categories, recipe]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isChangeError) {
            setSelectedCategoryIds(categoryIds);
        }
    }, [isChangeError]);

    const lists = categories.map<List>(category => ({
        id: category.id,
        title: category.name,
        amount: category.list.length,
        selected: selectedCategoryIds.includes(category.id),
        image:
            category?.recipes
                ?.filter(e => e.images.length > 0)[0]
                ?.images.find(({ rendition }) => rendition)?.url ?? '',
    }));

    const onSelectList = (categoryId: number) => {
        const newCategoryIds = selectedCategoryIds.includes(categoryId)
            ? selectedCategoryIds.filter(id => id !== categoryId)
            : [...selectedCategoryIds, categoryId];

        if (newCategoryIds.length) {
            setSelectedCategoryIds(newCategoryIds);
            return;
        }

        setSelectedCategoryIds([]);
    };

    const saveList = (onSaveListCompleted?: () => void) => {
        const isListChanged = categoryIds.sort() !== selectedCategoryIds.sort();

        if (isListChanged && recipe) {
            handleFavoriteAnalytics({
                recipeId: recipe.id,
                previousCategories: categoryIds,
                newCategories: selectedCategoryIds,
            });

            const type =
                recipe.type === RecipeOriginType.MEMBER_CREATED ||
                recipe.type === RecipeOriginType.MEMBER_SCRAPED
                    ? CollectionCategoryRecipeType.O
                    : CollectionCategoryRecipeType.A;

            if (selectedCategoryIds.length) {
                assignRecipe(
                    {
                        id: recipe.id,
                        type,
                        images: recipe.images,
                    },
                    selectedCategoryIds,
                    onSaveListCompleted,
                );
            } else {
                removeRecipe(recipe.id, type, onSaveListCompleted);
            }
        } else {
            onSaveListCompleted && onSaveListCompleted();
        }
    };

    const canCreateCategories = Boolean(
        member?.memberships?.includes(PERSONAL_BONUS_CARD_MEMBERSHIP),
    );

    const canFavoriteRecipes = Boolean(member?.isRegistered);

    const handleOnOpen = (
        recipe: HandleOnOpenRecipe,
        options?: HandleOnOpenOptions,
    ) => {
        setRecipe(recipe);
        setOptions(options);
        setSelectedCategoryIds(categoryIds);
        setIsModalOpen(true);
    };

    const handleOnClose = () => {
        setIsModalOpen(false);
    };

    return {
        isModalOpen,
        handleOnOpen,
        handleOnClose,

        recipeId: recipe?.id,
        recipeCreatedMessage: options?.recipeCreatedMessage,

        categories,
        createCategory,
        isLoading,
        isChangeLoading,
        isChangeError,

        previousCategoryIds: categoryIds,
        lists,
        onSelectList,
        saveList,
        canCreateCategories,
        canFavoriteRecipes,
        hasSaveListError: isChangeError,
        hasCreateCategoryError: createCategoryError,
    };
};
