'use client';

import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    FAVORITE_ADD_CATEGORY_NAME_FIELD,
    FAVORITE_ADD_CATEGORY_SUBMIT_BUTTON,
    FAVORITE_CLOSE_BUTTON,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import { ChevronLeft24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-left-24';
import { Close24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/close-24';
import { WarningFilled24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/warning-filled-24';
import {
    RegularButton,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import { ErrorMessage } from '@royalaholddelhaize/design-system-pantry-web/components/error-message/error-message';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalHeaderIconButton,
    ModalHeaderTitle,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/modal/modal';
import type { IDValuePair } from '@royalaholddelhaize/design-system-pantry-web/components/form/form.interfaces';
import { InputField } from '@royalaholddelhaize/design-system-pantry-web/components/form/input/field/input-field';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { type FC, type MouseEvent, useEffect, useRef, useState } from 'react';
import { getModalTranslations } from '../../utils/translations';

import css from './add-collection-modal.module.scss';

interface RecipeAddCollectionModalProps {
    isOpen: boolean;
    onCreate(name: string): void;
    onClose?: (event?: MouseEvent<HTMLButtonElement>) => void;
    onBack?: (event?: MouseEvent<HTMLButtonElement>) => void;
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;
    isCreatingCategory: boolean;
    errorCreatingCategory: boolean;
}

export const RecipeAddCollectionModal: FC<RecipeAddCollectionModalProps> = ({
    isOpen,
    onCreate,
    onBack,
    onClose,
    locale = DEFAULT_LOCALE,
    isCreatingCategory,
    errorCreatingCategory,
}) => {
    const { t } = getModalTranslations(locale);
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setInputError(false);
        setInputValue('');
    }, [isOpen]);

    const handleOnBack = () => {
        setInputError(false);
        onBack && onBack();
    };

    const handleOnClose = () => {
        setInputError(false);
        onClose && onClose();
    };

    const handleInputChange = (e: IDValuePair) => {
        setInputValue(e.value);
        setInputError(false);
    };

    const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const trimmedValue = inputValue.trim();
        if (trimmedValue.length > 0 && trimmedValue.length <= 50) {
            onCreate(trimmedValue);
        } else {
            setInputError(true);
            setInputValue('');
            inputRef.current?.focus();
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleOnClose}
            className={css.modal}
            data-brand="allerhande"
        >
            <ModalHeader className={css.modalHeader}>
                {onBack && (
                    <ModalHeaderIconButton
                        onClick={handleOnBack}
                        aria-label={t('action.back')}
                    >
                        <ChevronLeft24Icon aria-hidden />
                    </ModalHeaderIconButton>
                )}
                <ModalHeaderTitle aria-live="polite">
                    {t('list.formLabel')}
                </ModalHeaderTitle>
                {onClose && (
                    <ModalHeaderIconButton
                        onClick={handleOnClose}
                        aria-label={t('action.close')}
                        {...createTestHook(FAVORITE_CLOSE_BUTTON)}
                    >
                        <Close24Icon aria-hidden />
                    </ModalHeaderIconButton>
                )}
            </ModalHeader>

            <ModalContent className={css.modalContent}>
                <form className={css.form}>
                    <Typography variant="body-strong" className={css.newList}>
                        {t('list.formCreate')}
                    </Typography>
                    <InputField
                        ref={inputRef}
                        data-brand="ah"
                        id="category-name"
                        className={css.input}
                        value={inputValue}
                        onChangeValue={handleInputChange}
                        aria-label={t('list.formLabel')}
                        placeholder={t('list.formPlaceholder')}
                        aria-required={true}
                        error={inputError}
                        maxLength={50}
                        aria-describedby="create-error"
                        {...createTestHook(FAVORITE_ADD_CATEGORY_NAME_FIELD)}
                    />
                    {inputError && (
                        <ErrorMessage
                            id="create-error"
                            text={t('list.formError')}
                        />
                    )}
                </form>
            </ModalContent>
            <div className={css.modalFooter}>
                {errorCreatingCategory && (
                    <div className={css.errorWrapper}>
                        <WarningFilled24Icon
                            size="20"
                            aria-hidden
                            className={css.errorIcon}
                        />
                        <Typography
                            variant="body-regular"
                            className={css.errorMessage}
                        >
                            {t('list.saveError')}
                        </Typography>
                    </div>
                )}
                <RegularButton
                    onClick={handleSubmit}
                    className={css.button}
                    loading={isCreatingCategory}
                    data-brand="ah"
                    {...createTestHook(FAVORITE_ADD_CATEGORY_SUBMIT_BUTTON)}
                >
                    <RegularButtonText>{t('list.save')}</RegularButtonText>
                </RegularButton>
            </div>
        </Modal>
    );
};
