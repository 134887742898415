'use client';

import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    FAVORITE_ADD_CATEGORY_BUTTON,
    FAVORITE_CATEGORY_CHECKBOX,
    FAVORITE_CLOSE_BUTTON,
    FAVORITE_SAVE_CATEGORIES_BUTTON,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import { Close24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/close-24';
import { Plus16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/plus-16';
import { WarningFilled24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/warning-filled-24';
import { GeneralSuccess } from '@royalaholddelhaize/design-system-pantry-web/assets/illustrations/ah/spots/general-success';
import { LinkButton } from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import {
    RegularButton,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import {
    Checkbox,
    CheckboxTick,
} from '@royalaholddelhaize/design-system-pantry-web/components/checkbox/checkbox';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalHeaderIconButton,
    ModalHeaderTitle,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/modal/modal';
import { Spinner } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/spinner/spinner';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { useScroll } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-scroll/use-scroll';
import classNames from 'clsx';
import { type FC, type MouseEvent, useRef } from 'react';
import type { List } from '../../../../../interfaces/list';
import { getModalTranslations } from '../../utils/translations';

import css from './collections-modal.module.scss';

interface RecipeCollectionsModalProps {
    lists: List[];
    previousListIds: number[];
    isOpen: boolean;
    className?: string;
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;
    canCreateCategories: boolean;
    onOpenAddList: (event: MouseEvent<HTMLButtonElement> | MouseEvent) => void;
    onClose: () => void;
    onSelect: (id: number) => void;
    onSave: () => void;
    recipeCreatedMessage?: string;
    isLoading?: boolean;
    isSavingInCategory: boolean;
    errorSavingInCategory: boolean;
}

export const RecipeCollectionsModal: FC<RecipeCollectionsModalProps> = ({
    isOpen,
    isLoading,
    lists,
    previousListIds,
    onClose,
    onSave,
    onSelect,
    onOpenAddList,
    locale = DEFAULT_LOCALE,
    isSavingInCategory,
    errorSavingInCategory,
    recipeCreatedMessage,
}) => {
    const { t } = getModalTranslations(locale);
    const listRef = useRef<HTMLDivElement>(null);
    const { scrollYProgress } = useScroll({
        ref: listRef,
    });

    const isDelete =
        previousListIds?.length > 0 &&
        lists.filter(list => list.selected).length === 0;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            className={css.modal}
            data-brand="allerhande"
        >
            <ModalHeader className={css.modalHeader}>
                <ModalHeaderTitle aria-live="polite">
                    {t('list.add')}
                </ModalHeaderTitle>
                <ModalHeaderIconButton
                    onClick={onClose}
                    aria-label={t('action.close')}
                    {...createTestHook(FAVORITE_CLOSE_BUTTON)}
                >
                    <Close24Icon aria-hidden />
                </ModalHeaderIconButton>
            </ModalHeader>

            <ModalContent className={css.modalContent} ref={listRef}>
                {isLoading ? (
                    <div className={css.spinner}>
                        <Spinner size={24} />
                    </div>
                ) : (
                    <>
                        {recipeCreatedMessage && (
                            <div className={css.recipeCreated}>
                                <GeneralSuccess size={150} aria-hidden />
                                <Typography align="center">
                                    {recipeCreatedMessage}
                                </Typography>
                            </div>
                        )}
                        <ul className={css.listRoot}>
                            <li className={css.listItem}>
                                <LinkButton
                                    onClick={onOpenAddList}
                                    className={css.button}
                                    {...createTestHook(
                                        FAVORITE_ADD_CATEGORY_BUTTON,
                                    )}
                                >
                                    <div className={css.listIconAdd}>
                                        <Plus16Icon
                                            size={20}
                                            aria-hidden
                                            className={css.addListIcon}
                                        />
                                    </div>
                                    <Typography className={css.listTitle}>
                                        {t('list.newCategory')}
                                    </Typography>
                                </LinkButton>
                            </li>
                            {lists.map(
                                ({
                                    id,
                                    title,
                                    amount,
                                    image,
                                    selected,
                                }: List) => {
                                    return (
                                        <li className={css.listItem} key={id}>
                                            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                                            <div
                                                // biome-ignore lint/a11y/useSemanticElements: <explanation>
                                                role="checkbox"
                                                onClick={() => onSelect(id)}
                                                className={css.button}
                                                aria-label={`${title} - ${amount} ${t('list.recipes')}`}
                                                aria-checked={selected}
                                                tabIndex={0}
                                            >
                                                {!image ? (
                                                    <div
                                                        className={
                                                            css.defaultContentIcon
                                                        }
                                                        aria-hidden
                                                    />
                                                ) : (
                                                    <img
                                                        alt={title}
                                                        src={image}
                                                        className={
                                                            css.contentIcon
                                                        }
                                                        aria-hidden
                                                    />
                                                )}
                                                <div
                                                    className={css.text}
                                                    aria-hidden
                                                >
                                                    <Typography
                                                        className={css.label}
                                                    >
                                                        {title}
                                                    </Typography>
                                                    <Typography
                                                        variant="subtext-regular"
                                                        className={css.count}
                                                    >
                                                        {`${amount} ${t('list.recipes')}`}
                                                    </Typography>
                                                </div>
                                                <Checkbox
                                                    id={`list-${id}`}
                                                    aria-hidden
                                                    aria-label=" "
                                                    checked={selected}
                                                    onClick={() => onSelect(id)}
                                                    className={css.checkbox}
                                                    data-brand="ah"
                                                    {...createTestHook(
                                                        FAVORITE_CATEGORY_CHECKBOX,
                                                    )}
                                                >
                                                    <CheckboxTick />
                                                </Checkbox>
                                            </div>
                                        </li>
                                    );
                                },
                            )}
                        </ul>
                    </>
                )}
            </ModalContent>

            <div
                className={classNames(
                    css.modalFooter,
                    errorSavingInCategory && css.modalFooterWithError,
                    {
                        [css.modalFooterShadow]: scrollYProgress < 1,
                    },
                )}
            >
                {errorSavingInCategory && (
                    <div className={css.errorWrapper}>
                        <WarningFilled24Icon
                            size="20"
                            aria-hidden
                            className={css.errorIcon}
                        />
                        <Typography
                            variant="body-regular"
                            className={css.errorMessage}
                        >
                            {t('list.saveError')}
                        </Typography>
                    </div>
                )}
                <RegularButton
                    className={css.buttonConfirm}
                    onClick={onSave}
                    loading={isSavingInCategory}
                    buttonStyle={isDelete ? 'destructive' : 'regular'}
                    data-brand="ah"
                    {...createTestHook(FAVORITE_SAVE_CATEGORIES_BUTTON)}
                >
                    <RegularButtonText>
                        {isDelete ? t('list.delete') : t('list.save')}
                    </RegularButtonText>
                </RegularButton>
            </div>
        </Modal>
    );
};
