'use client';

import { Portal } from '@royalaholddelhaize/design-system-pantry-web/components/utilities/portal/portal';
import type { FC, PropsWithChildren } from 'react';
import { RecipeCollectionModal } from '../components/modals/recipe-collection-modal/recipe-collection-modal';
import { useRecipeCollectionModalHook } from '../components/modals/recipe-collection-modal/recipe-collection-modal-hook';
import { RecipeCollectionContext } from '../contexts/recipe-collection-context';

interface RecipeCollectionProviderProps extends PropsWithChildren {
    member: {
        memberships: string[];
        isRegistered: boolean;
    };
}

export const RecipeCollectionProvider: FC<RecipeCollectionProviderProps> = ({
    children,
    member,
}) => {
    const {
        isModalOpen,
        handleOnOpen,
        handleOnClose,
        recipeId,
        hasCreateCategoryError,
        saveList,
        hasSaveListError,
        previousCategoryIds,
        lists,
        onSelectList,
        createCategory,
        canCreateCategories,
        canFavoriteRecipes,
        categories,
        isLoading,
        isChangeLoading,
        isChangeError,
        recipeCreatedMessage,
    } = useRecipeCollectionModalHook({ member });

    return (
        <RecipeCollectionContext.Provider
            value={{
                isModalOpen,
                handleOnOpen,
                handleOnClose,
                categories,
                isLoading,
                isChangeLoading,
                isChangeError,
                canCreateCategories,
                canFavoriteRecipes,
            }}
        >
            {children}
            <Portal>
                {isModalOpen && (
                    <RecipeCollectionModal
                        isOpen={isModalOpen}
                        id={recipeId}
                        errorCreatingCategory={hasCreateCategoryError}
                        errorSavingInCategory={hasSaveListError}
                        onClose={handleOnClose}
                        previousListIds={previousCategoryIds}
                        lists={lists}
                        onSelectList={onSelectList}
                        onCreateCategory={createCategory}
                        onSaveList={saveList}
                        canCreateCategories={canCreateCategories}
                        canFavoriteRecipes={canFavoriteRecipes}
                        recipeCreatedMessage={recipeCreatedMessage}
                    />
                )}
            </Portal>
        </RecipeCollectionContext.Provider>
    );
};
