import {
    emitAddRecipeToFavorites,
    emitMoveFavoriteRecipe,
    emitRemoveRecipeFromFavorites,
} from '@royalaholddelhaize/ah-analytics';

interface HandleFavoriteAnalyticsProps {
    previousCategories: number[];
    newCategories: number[];
    recipeId: number;
}

export const handleFavoriteAnalytics = ({
    previousCategories,
    newCategories,
    recipeId,
}: HandleFavoriteAnalyticsProps): void => {
    const isLongerList = previousCategories.length < newCategories.length;
    const isShorterList = previousCategories.length > newCategories.length;
    const isSameLengthList = previousCategories.length === newCategories.length;

    // add: The number of checkboxes increases
    if (isLongerList) {
        emitAddRecipeToFavorites({
            id: recipeId,
            listName: 'allerhande',
        });
    }

    // remove: The number of checkboxes decreases
    if (isShorterList) {
        emitRemoveRecipeFromFavorites({
            id: recipeId,
            listName: 'allerhande',
        });
    }

    // move: The selection of checkboxes changed and the number of checkboxes remains unchanged
    if (isSameLengthList) {
        emitMoveFavoriteRecipe({
            id: recipeId,
            listName: 'allerhande',
        });
    }
};
