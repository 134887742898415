import type { ComponentProps, FC } from 'react';

export const HeartPlusIcon: FC<ComponentProps<'svg'>> = ({ ...props }) => (
    <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>Heart Plus Icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 13.5a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zm-6.2-2.8a.7.7 0 111.4 0v2.1h2.1a.7.7 0 110 1.4h-2.1v2.1a.7.7 0 11-1.4 0v-2.1h-2.1a.7.7 0 110-1.4h2.1v-2.1z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 18.627c-3.2-2.096-8-6.124-8-9.51C4 6.829 5.669 4.959 7.74 5c1.202 0 2.31.672 2.976 1.81l.425.712a1 1 0 001.717 0l.431-.72c.66-1.131 1.767-1.801 2.984-1.801 1.398-.014 2.608.826 3.247 2.078.86.135 1.664.44 2.377.877-.51-2.84-2.854-4.966-5.635-4.955-1.465 0-2.87.636-3.896 1.718a.5.5 0 01-.734 0C10.605 3.636 9.204 3 7.752 3 4.594 2.943 2 5.718 2 9.118c0 5.37 7.889 10.56 9.471 11.545l.529.329.528-.33c.403-.25 1.216-.776 2.203-1.509l-1.442-1.416c-.447.325-.88.623-1.289.89z"
            fill="currentColor"
        />
    </svg>
);
