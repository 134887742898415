import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import { type FC, useEffect, useState } from 'react';
import type { List } from '../../../interfaces/list';
import { HeartPlusIcon } from './assets/heart-plus';
import { RecipeAddCollectionModal } from './elements/add-collection-modal/add-collection-modal';
import { RecipeCollectionsModal } from './elements/collections-modal/collections-modal';
import { LoginModal } from './elements/login-modal/login-modal';
import { NoBonusCardModal } from './elements/no-bonus-card-modal/no-bonus-card-modal';
import { getModalTranslations } from './utils/translations';

export interface RecipeCollectionModalProps {
    id?: number;
    isOpen: boolean;
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;
    errorCreatingCategory: boolean;
    errorSavingInCategory: boolean;
    onClose: () => void;
    recipeCreatedMessage?: string;
    previousListIds: number[];
    lists: List[];
    onSelectList: (categoryId: number) => void;
    onCreateCategory: (
        name: string,
        onCategoryCreatedCompleted: (hasError?: boolean) => void,
    ) => void;
    onSaveList: (onSaveCompleted: (hasError?: boolean) => void) => void;
    onCloseWithoutSave?: () => void;
    canCreateCategories: boolean;
    canFavoriteRecipes: boolean;
}

export const RecipeCollectionModal: FC<RecipeCollectionModalProps> = ({
    onClose,
    isOpen,
    locale = DEFAULT_LOCALE,
    previousListIds,
    lists,
    canCreateCategories,
    onCreateCategory,
    onSelectList,
    onSaveList,
    onCloseWithoutSave,
    canFavoriteRecipes,
    recipeCreatedMessage,
    errorSavingInCategory = false,
    errorCreatingCategory = false,
}) => {
    const [isAddListOpen, setIsAddListOpen] = useState<boolean>(false);
    const [favoriteListOpen, setFavoriteListOpen] = useState<boolean>(false);
    const [isRequestInProgress, setIsRequestInProgress] =
        useState<boolean>(false);
    const { t } = getModalTranslations(locale);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isOpen && isOpen !== favoriteListOpen) {
            setFavoriteListOpen(true);
        }
        if (!isOpen) {
            setFavoriteListOpen(false);
            setIsAddListOpen(false);
        }
    }, [isOpen]);

    const openAddList = () => {
        setFavoriteListOpen(false);
        setIsAddListOpen(true);
    };

    const closeAddList = () => {
        setIsAddListOpen(false);
        setFavoriteListOpen(true);
    };

    const closeLoginModal = () => {
        onClose();
    };

    const onCategoryCreatedCompleted = (hasError?: boolean) => {
        setIsRequestInProgress(false);
        if (!hasError) {
            setIsAddListOpen(false);
            setFavoriteListOpen(true);
        }
    };

    const handleCreateCategory = (name: string) => {
        setIsRequestInProgress(true);
        onCreateCategory(name, onCategoryCreatedCompleted);
    };

    const closeModal = () => {
        setIsAddListOpen(false);
        setFavoriteListOpen(false);
        onClose();
    };

    const closeWithoutSave = () => {
        onCloseWithoutSave && onCloseWithoutSave();
        closeModal();
    };

    const onSaveCompleted = (hasError?: boolean) => {
        setIsRequestInProgress(false);
        if (!hasError) {
            closeModal();
        }
    };

    const onSave = () => {
        setIsRequestInProgress(true);
        onSaveList(onSaveCompleted);
    };

    if (!canFavoriteRecipes) {
        return (
            <LoginModal
                isOpen={favoriteListOpen}
                onClose={closeLoginModal}
                title={t('loginModal.title')}
                description={t('loginModal.description')}
                icon={HeartPlusIcon}
            />
        );
    }

    if (isAddListOpen) {
        return canCreateCategories ? (
            <RecipeAddCollectionModal
                isOpen={isAddListOpen}
                onClose={closeWithoutSave}
                onCreate={handleCreateCategory}
                onBack={closeAddList}
                isCreatingCategory={isRequestInProgress}
                errorCreatingCategory={errorCreatingCategory}
            />
        ) : (
            <NoBonusCardModal
                isOpen={isAddListOpen}
                onClose={onClose}
                onBack={closeAddList}
            />
        );
    }

    return (
        <RecipeCollectionsModal
            onSave={onSave}
            isOpen={favoriteListOpen}
            onSelect={onSelectList}
            onOpenAddList={openAddList}
            onClose={closeWithoutSave}
            lists={lists}
            previousListIds={previousListIds}
            canCreateCategories={canCreateCategories}
            recipeCreatedMessage={recipeCreatedMessage}
            isSavingInCategory={isRequestInProgress}
            errorSavingInCategory={errorSavingInCategory}
        />
    );
};
