import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    LOGIN_MODAL,
    LOGIN_MODAL_CLOSE_BUTTON,
    LOGIN_MODAL_LOGIN_BUTTON,
    LOGIN_MODAL_REGISTER_BUTTON,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import { DEFAULT_LOCALE } from '@royalaholddelhaize/ah-web-core';
import { Close24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/close-24';
import { ButtonGroup } from '@royalaholddelhaize/design-system-pantry-web/components/button/button-group/button-group';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import {
    RegularAnchor,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalHeaderIconButton,
    ModalHeaderTitle,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/modal/modal';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { type ComponentProps, type FC, useEffect, useState } from 'react';
import { getModalTranslations } from '../../utils/translations';
import css from './login-modal.module.scss';

const LOGIN = '/login';
const REGISTER = '/mijn/inschrijven/invullen?webshop=true';

interface LoginModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    description: string;
    icon: FC<ComponentProps<'svg'>>;
}

export const LoginModal: FC<LoginModalProps> = ({
    isOpen = false,
    onClose,
    title,
    description,
    icon: Icon,
}) => {
    const [pathname, setPathname] = useState<string>();

    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);
    const locale = DEFAULT_LOCALE;
    const { t } = getModalTranslations(locale);
    const encodedPathname = encodeURI(`${pathname}`);
    const loginUrl = `${LOGIN}?ref=${encodedPathname}`;
    const registerUrl = `${REGISTER}&ref=${encodedPathname}`;

    return (
        <Modal
            className={css.modal}
            isOpen={isOpen}
            onClose={onClose}
            data-brand="ah"
            {...createTestHook(LOGIN_MODAL)}
        >
            <ModalHeader className={css.modalHeader}>
                <ModalHeaderTitle aria-live="polite">{title}</ModalHeaderTitle>
                <ModalHeaderIconButton
                    onClick={onClose}
                    aria-label={t('action.close')}
                    {...createTestHook(LOGIN_MODAL_CLOSE_BUTTON)}
                >
                    <Close24Icon aria-hidden />
                </ModalHeaderIconButton>
            </ModalHeader>

            <ModalContent className={css.modalContent}>
                <Icon className={css.icon} width={80} height={80} aria-hidden />

                <Typography className={css.modalDescription} align="center">
                    {description}
                </Typography>
            </ModalContent>

            <ButtonGroup data-brand="ah" className={css.modalFooter}>
                <RegularAnchor
                    href={registerUrl}
                    className={css.registerButton}
                    {...createTestHook(LOGIN_MODAL_REGISTER_BUTTON)}
                >
                    <RegularButtonText>
                        {t('loginModal.register')}
                    </RegularButtonText>
                </RegularAnchor>
                <LinkAnchor
                    href={loginUrl}
                    {...createTestHook(LOGIN_MODAL_LOGIN_BUTTON)}
                >
                    <LinkText>{t('loginModal.login')}</LinkText>
                </LinkAnchor>
            </ButtonGroup>
        </Modal>
    );
};
