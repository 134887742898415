import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import { ChevronLeft24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-left-24';
import { Close24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/close-24';
import {
    RegularButton,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalHeaderIconButton,
    ModalHeaderTitle,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/modal/modal';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC, MouseEvent } from 'react';
import { getModalTranslations } from '../../utils/translations';

import css from './no-bonus-card-modal.module.scss';

const ACTIVATE_PERSONAL_BONUS_CARD = '/mijn/geactiveerde-bonuskaart';

interface NoBonusCardModalProps {
    isOpen: boolean;
    onClose(event?: MouseEvent<HTMLButtonElement>): void;
    onBack?(event?: MouseEvent<HTMLButtonElement>): void;
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;
}

export const NoBonusCardModal: FC<NoBonusCardModalProps> = ({
    isOpen,
    onClose,
    onBack,
    locale = DEFAULT_LOCALE,
}) => {
    const { t } = getModalTranslations(locale);

    const handleOnClose = () => {
        onClose();
    };

    const handleOnBack = () => {
        onBack && onBack();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleOnClose}
            className={css.modal}
            data-brand="allerhande"
        >
            <ModalHeader className={css.modalHeader}>
                <ModalHeaderIconButton
                    onClick={handleOnBack}
                    aria-label={t('action.back')}
                >
                    <ChevronLeft24Icon aria-hidden />
                </ModalHeaderIconButton>
                <ModalHeaderTitle aria-live="polite">
                    {t('noBonusCard.title')}
                </ModalHeaderTitle>
                <ModalHeaderIconButton
                    onClick={handleOnClose}
                    aria-label={t('action.close')}
                >
                    <Close24Icon aria-hidden />
                </ModalHeaderIconButton>
            </ModalHeader>

            <ModalContent className={css.modalContent}>
                <Typography variant="body-strong">
                    {t('noBonusCard.subtitle')}
                </Typography>
                <Typography variant="body-regular">
                    {t('noBonusCard.description')}
                </Typography>
            </ModalContent>

            <div className={css.modalFooter}>
                <a
                    className={css.button}
                    href={ACTIVATE_PERSONAL_BONUS_CARD}
                    data-brand="ah"
                >
                    <RegularButton>
                        <RegularButtonText>
                            {t('noBonusCard.link')}
                        </RegularButtonText>
                    </RegularButton>
                </a>
            </div>
        </Modal>
    );
};
